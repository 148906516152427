<template>
  <div class="home">
    Home.vue Page
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: { }
}
</script>
