<template>
  <div>
    <b-form-checkbox v-model="ux.developer" value="true" unchecked-value="false">Developer</b-form-checkbox>
    <b-button :variant="(client.connected)?'outline-success':'outline-secondary'" @click.prevent="(client.connected)?destroyConnection():createConnection()">Connect</b-button>
    <b-button @click.prevent="doSubscribe()">doSubscribe</b-button>
    <b-button @click.prevent="doUnSubscribe()">doUnSubscribe</b-button>
    <b-button @click.prevent="doPublish()">doPublish</b-button>
    <pre v-if="ux.developer == 'true'">{{connection}}</pre>
    <pre v-if="ux.developer == 'true'">client: {{client.connected}}</pre>
    <pre v-if="ux.developer == 'true'">temp: {{temp}}</pre>
    <pre v-if="ux.developer == 'true'">ux: {{ux}}</pre>
    <div v-if="client.connected == true">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <b-form-checkbox v-bind:key="client" v-model="ux.selectClient[client]" value="accepted" unchecked-value="not_accepted" v-for="client in temp.client">{{client}}</b-form-checkbox>

      <!-- Rounded switch -->
      <label class="switch">
        <input type="checkbox" v-model="ux.onoff.power1" @change="OnOffChange('power1')">
        <span class="slider round"></span>
      </label>

      <!-- Rounded switch -->
      <label class="switch">
        <input type="checkbox" v-model="ux.onoff.power2" @change="OnOffChange('power2')">
        <span class="slider round"></span>
      </label>
      <div><pre>{{ux.timer}}</pre></div>
    </div>
  </div>
</template>

<style scoped>
  /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>


<script>

  import mqtt from 'mqtt'

  export default {
    name: 'mttq',
    props: { },
    data: function() {
      return {
        ux:{
          autoPower: true,
          developer: false,
          selectClient:{},
          onoff: {
            power1: false,
            power2: false
          },
          timer:{
            power1: 0,
            power2: 0,
            test:0,
          },
        },
        temp: {
          client: ["tasmota_camera",
    "tasmota_cameretta",
    "tasmota_cucina"],
        },
      connection: {
        host: 'broker2.ivanc.eu',
        port: 9001,
        endpoint: '/',
        clean: true, // Reserved session
        connectTimeout: 4000, // Time out
        reconnectPeriod: 4000, // Reconnection interval
        // Certification Information
        clientId: 'mqttjs_3be2c321',
        username: 'client',
        password: 'client',
      },
      subscription: {
        topic: 'topic/browser',
        qos: 0,
      },
      publish: {
        topic: 'topic/browser',
        qos: 0,
        payload: '{ "msg": "Hello, I am browser." }',
      },
      receiveNews: [],
      qosList: [
        { label: 0, value: 0 },
        { label: 1, value: 1 },
        { label: 2, value: 2 },
      ],
      client: {
        connected: false,
      },
      subscribeSuccess: false,
      timerCount: 30
    }
  },
  created: function () {
    this.countDownTimer();
    this.createConnection();
  },
    methods: {
      countDownTimer(){
        this.ux.timer.test++;
        /*this.timerCount--;
        if(this.timerCount <= 0){
          this.timerCount = 30;
        }*/
        if(this.ux.onoff.power1){
          this.ux.timer.power1++;
          if((this.ux.timer.power1 == 5)&&(this.ux.autoPower)){
            this.ux.onoff.power1 = false;
            this.OnOffChange("power1");
          }
        }
        if(this.ux.onoff.power2){
          this.ux.timer.power2++;
          if((this.ux.timer.power2 == 12)&&(this.ux.autoPower)){
            this.ux.onoff.power2 = false;
            this.OnOffChange("power2");
          }
        }
        setTimeout(() => {
                            this.countDownTimer();
                        }, 1000);
      },
      pubblisher(switc, value){
        console.log("pubblisher");
        for (const prop in this.ux.selectClient) {
          if(this.ux.selectClient[prop] == "accepted"){
            this.publish.topic = "cmnd/"+prop+"/"+switc;
            this.publish.payload = value;
            console.log(this.publish);
            this.doPublish();
          }
        }
      },
      OnOffChange(chgElement){
        if(chgElement == "power1"){
          this.ux.onoff.power2 = false;
          if(this.ux.onoff.power1 == true){
            this.ux.timer.power1 = 0;
            this.pubblisher("Power2", "OFF");
            this.pubblisher("Power1", "ON");
          } else {
            this.pubblisher("Power2", "OFF");
            this.pubblisher("Power1", "OFF");
          }

        } else if(chgElement == "power2"){
          // empty
          this.ux.onoff.power1 = false;
          if(this.ux.onoff.power2 == true){
            this.ux.timer.power2 = 0;
            this.pubblisher("Power1", "OFF");
            this.pubblisher("Power2", "ON");
          } else {
            this.pubblisher("Power2", "OFF");
            this.pubblisher("Power1", "OFF");
          }
        } else {
          // empty
        }
        console.log("change"+chgElement);
      },
      isJson(str) {
          try {
              JSON.parse(str);
          } catch (e) {
              return false;
          }
          return true;
      },
      createConnection() {
      // Connect string, and specify the connection method used through protocol
      // ws unencrypted WebSocket connection
      // wss encrypted WebSocket connection
      // mqtt unencrypted TCP connection
      // mqtts encrypted TCP connection
      // wxs WeChat mini app connection
      // alis Alipay mini app connection
      if(this.client.connected){
        return false;
      }
      const { host, port, endpoint, ...options } = this.connection
      const connectUrl = `wss://${host}:${port}${endpoint}`
      try {
        this.client = mqtt.connect(connectUrl, options)
      } catch (error) {
        console.log('mqtt.connect error', error)
      }
      this.client.on('connect', () => {
        this.client.connected;
        console.log('Connection succeeded!')
      })
      this.client.on('error', error => {
        console.log('Connection failed', error)
      })
      this.client.on('message', (topic, message) => {
        var x = {};
        x['topic'] = topic;
        var stringMessage = new TextDecoder().decode(message);
        if(this.isJson(stringMessage)){
          x['message'] = JSON.parse(stringMessage);
        } else {
          x['message'] = stringMessage;
        }
        this.receiveNews.push(x);
        this.addClientFromTopic(topic);
        console.log(`Received message ${message} from topic ${topic}`)
      })
    },
    addClientFromTopic(topic){
      var str = topic.split("/");
      console.log(str[1]);
      if ( this.temp.client.includes(str[1], 0) ) { 
        // empty
      } else {
        this.temp.client.push(str[1]);
      }
    },
    doSubscribe() {
      const { topic, qos } = this.subscription
      this.client.subscribe(topic, { qos }, (error, res) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        this.subscribeSuccess = true
        console.log('Subscribe to topics res', res)
        })
    },
    doUnSubscribe() {
  const { topic } = this.subscription
  this.client.unsubscribe(topic, error => {
    if (error) {
      console.log('Unsubscribe error', error)
    }
  })
},
doPublish() {
  const { topic, qos, payload } = this.publish
  this.client.publish(topic, payload, qos, error => {
    if (error) {
      console.log('Publish error', error)
    }
  })
},
destroyConnection() {
  if (this.client.connected) {
    try {
      this.client.end()
      this.client = {
        connected: false,
      }
      console.log('Successfully disconnected!')
    } catch (error) {
      console.log('Disconnect failed', error.toString())
    }
  }
},
    }
  }
</script>